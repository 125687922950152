import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import CompleteProfileComponent from "../components/auth/completeprofile-component";



const CompleteProfilePage = () => (

 <CompleteProfileComponent></CompleteProfileComponent>

)

export default CompleteProfilePage;