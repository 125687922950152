import React from "react"
import firebase from "./fire-base.js"
import Logo from "../../images/orange.png"
import { FaCamera } from "react-icons/fa"
import {navigate} from "gatsby"

class CompleteProfileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      error: "",
      category: [],
      categoryError: "",
      picurl: null,
      image: null,
      progress: 0,
    }
    this.displayError = React.createRef()
    this.displayCategoryError = React.createRef()
    this.categorySelect = React.createRef()
    this.handleFileClick = this.handleFileClick.bind(this)
    this.fileSelector = React.createRef()
  }

  componentDidMount() {

    if(firebase.auth().currentUser === null || firebase.auth().currentUser === undefined ){
      navigate("/signin")
    }

    var user = firebase.auth().currentUser;
    if (user !== null) {
      this.userStatus(user);
    }
    else {
      firebase.auth().onAuthStateChanged( (thisUser) => {

      if(thisUser !== null && thisUser !== undefined ){
        this.userStatus(thisUser)
      }
      else {
        if (window.location.pathname !== "" && window.location.pathname !== "/") {
          navigate("/")
        }
       }
      })
    }
    this.populateCategory()
  }

  populateCategory() {
    firebase
      .firestore()
      .collection("dropdowns")
      .doc("category")
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({ category: doc.data().categories })
        }
      })
  }

  userStatus(user) {
    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          var data = doc.data()
          if (data.username !== "" && data.username !== undefined && data.username !== null) {
            localStorage.setItem('first_login_to_cloudpin', 'first');
            navigate("/home/")
          }
        }
      })
  }

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.name === "username") {
      if (event.target.value.trim() !== "") {
        if (this.state.error === "Username Required") {
          this.displayError.current.hidden = true
        }
      }
      if (event.target.value.trim() === "") {
        this.setState({ error: "Username Required" })
        this.displayError.current.hidden = false
      }
    }
  }

  async addUser(user) {
    await firebase
      .firestore()
      .collection("username")
      .doc(this.state.username)
      .set({})
    await firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .set(
        {
          username: this.state.username,
          uid: user.uid,
          email: user.email,
          memberSince: new Date(),
          displayPic: this.state.picurl,
          category: this.categorySelect.current.value,
        }, {merge: true}
      )
  }

  valueChanged() {
    if (this.categorySelect.current.value !== "Category") {
      this.displayCategoryError.current.hidden = true
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const { username, error } = this.state
    var category = this.categorySelect.current.value
    if (username.trim() !== "" && category !== "Category") {
      if (
        username.match("^[a-zA-Z0-9]*$") &&
        username.length >= 2 &&
        username.length <= 15
      ) {
        var yourRef = firebase
          .firestore()
          .collection("username")
          .doc(username)
        var getDoc = yourRef
          .get()
          .then(doc => {
            if (!doc.exists) {
              this.addUser(firebase.auth().currentUser)
              this.displayError.current.hidden = true
              setTimeout(() => {
                localStorage.setItem('first_login_to_cloudpin', 'first');
                navigate("/home/")
              }, 2000)
            } else {
              this.setState({ error: "The username is taken" })
              this.displayError.current.hidden = false
            }
          })
          .catch(error => {})
      } else {
        this.setState({
          error:
            "Username must be alphanumeric, at least 2 chars,\n & less than 15 chars",
        })
        this.displayError.current.hidden = false
      }
    } else {
      if (username.trim() === "" && category === "Category") {
        this.setState({ error: "Username Required" })
        this.setState({ categoryError: "Category Required" })
        this.displayCategoryError.current.hidden = false
        this.displayError.current.hidden = false
      } else {
        if (username.trim() === "") {
          this.setState({ error: "Username Required" })
          this.displayError.current.hidden = false
        } else if (category === "Category") {
          this.setState({ categoryError: "Category Required" })
          this.displayCategoryError.current.hidden = false
        }
      }
    }
  }

  handleFileClick = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0]
      this.setState({ image: image, picurl: null }, () => {
        const { image } = this.state
        const uploadTask = firebase
          .storage()
          .ref(`displaypics/${image.name}`)
          .put(image)
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            this.setState({ progress })
            console.log(progress)
          },
          error => {
            // Error function ...
            console.log(error)
          },
          () => {
            // complete function ...
            firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .get().then((userInfo)=>{
                  if(userInfo) {
                    var userDisplayPic = userInfo.data().displayPic ? userInfo.data().displayPic : ""
                    if(userDisplayPic !== "") {
                      const store = firebase.storage().refFromURL(userDisplayPic);
                      store.delete();
                    }
                  }
                })
            firebase
              .storage()
              .ref("displaypics")
              .child(image.name)
              .getDownloadURL()
              .then(picurl => {
                this.setState({ picurl })
              })
          }
        )
      })
    }
  }

  render() {
    const { username, error } = this.state

    return (
      <>
        <div className="auth-header">
          <img src={Logo} className="auth-logo" />
        </div>

        <div className="auth-form-wrapper">
          <form className="auth-form col-sm-6 col-md-6 col-lg-4" onSubmit={this.handleSubmit}>
            <h5 className="text-center">Complete Your Profile</h5>
            <br />
            <div className="text-center">
              <div
                className="text-center"
                onClick={
                  this.fileSelector && this.fileSelector.current
                    ? () => this.fileSelector.current.click()
                    : void 0
                }
                style={{
                  border: "2px dotted #ff755e",
                  opacity: ".7",
                  borderRadius: "50%",
                  height: "85px",
                  width: "90px",
                  display: "inline-block",
                }}
              >
                <input
                  id="file-selector"
                  type="file"
                  onChange={this.handleFileClick}
                  ref={this.fileSelector}
                  hidden
                />
                {this.state.picurl ? (
                  <img
                    src={this.state.picurl}
                    id="profile-pic"
                    style={{
                      borderRadius: "50%",
                    }}
                  ></img>
                ) : (
                  <FaCamera
                    style={{
                      color: "#ff755e",
                      width: "30px",
                      height: "30px",
                      marginTop: "25px",
                      cursor: "pointer",
                    }}
                  ></FaCamera>
                )}
              </div>
            </div>
            <br />
            <div className="form-group text-center">
              <input
                placeholder="Username"
                name="username"
                onChange={this.handleInputChange}
                type="text"
                className="auth-input"
              />
            </div>
            <div className="form-group text-center">
              <select
                ref={this.categorySelect}
                id="categories"
                className="auth-input"
                onChange={() => this.valueChanged()}
                style={{ background: "white" }}
              >
                <option hidden>Category</option>
                {this.state.category.map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
                ;
              </select>
            </div>
            <div className="text-center">
              <button className="auth-btn col-md-10">Finish</button>
            </div>
            <p
              hidden
              id="error"
              style={{ textAlign: "center", color: "red" }}
              ref={this.displayError}
            >
              {this.state.error}
            </p>
            <p
              hidden
              id="error"
              style={{ textAlign: "center", color: "red" }}
              ref={this.displayCategoryError}
            >
              {this.state.categoryError}
            </p>
          </form>
        </div>
      </>
    )
  }
}

export default CompleteProfileComponent
